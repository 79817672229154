import { PageProps } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Programma from '../containers/programma';

const ProgrammaPage: React.FunctionComponent<PageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Programma degli eventi"
        description="Gli eventi in programma per i festeggiamenti dei primi cento anni di attività."
      />

      <Programma />
    </Layout>
  );
};

export default ProgrammaPage;
