import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from '../../components/gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
  IoLogoInstagram,
} from 'react-icons/io';
import {
  ProgrammaWrapper,
  ProgrammaImage,
  ProgrammaPageTitle,
  ProgrammaDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/people/Gruppo-Scout-FSE-Este-1/100090029058957/',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/gruppoeste1.fse/',
    tooltip: 'Instagram',
  },
];

interface ProgrammaProps { }

const Programma: React.FunctionComponent<ProgrammaProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/locandina_low.jpeg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <ProgrammaWrapper>
      <ProgrammaPageTitle>
        <h2>La locandina</h2>
        <p>
          Ecco la locandina che abbiamo preparato per i festeggiamenti del centenario. E' ricca di simboli, riesci a trovarli tutti?
        </p>
      </ProgrammaPageTitle>

      <ProgrammaImage>
        <GatsbyImage src={Data.avatar.childImageSharp.gatsbyImageData} alt="programma" />
      </ProgrammaImage>

      <ProgrammaDetails>
        <h2>Gli eventi</h2>
        <p>
          <ul>
            <li>
              Domenica 12 Febbraio ore 8:30-11:30 Partenza dal Patronato SS. Redentore per la pulizia strade di Este con tutto il Gruppo, ore 12:00 Santa Messa alle Grazie. Sono invitati anche i genitori e i Vecchi Scout, Guide di Ieri a cui chiediamo di portare con sé guanti e giubbetto alta visibilità.
            </li>
            <li>
              Mercoledì 22 Febbraio ore 16:00 Santa Messa delle ceneri in Duomo per le prime branche. Ore 22:00 Veglia delle ceneri in Patronato per seconde e terze branche.
            </li>
            <li>
              Sabato 25 Febbraio ore 18:00-19:00 consegna del “fondo Ettore Bressan” al Gabinetto di Lettura, parteciperanno i Capi unità con i Vecchi Scout e le Guide di Ieri.
            </li>
            <li>
              Domenica 02 Aprile ore 12:20 scopertura della targa commemorativa presso l’abitazione di Ettore Bressan, parteciperà tutto il Gruppo dopo la Santa Messa delle Palme. Sono invitati anche i genitori, Vecchi Scout e Guide di Ieri.
            </li>
            <li>
              Domenica 23 Aprile ore 15:30 rinnovo delle Promesse presso Villa Benvenuti (le prime branche faranno attività dalla mattina). Presente tutto il Gruppo, sono invitati i Vecchi Scout e le Guide di Ieri.
            </li>
            <li>
              Sabato 6 e Domenica 7 Maggio grande gioco per le vie di Este ed il parco di Villa Benvenuti per i ragazzi del Gruppo.
              Seconde e terze branche inizieranno Sabato 6 alle ore 15:00, mentre le prime branche giocheranno la domenica. Conclusione per tutti alle ore 15:00 della Domenica.
            </li>
            <li>
              Venerdì 26 Maggio ore 18:00 inaugurazione della mostra scout presso la Pescheria Vecchia, con tutto il Gruppo, genitori, Vecchi Scout e Guide di Ieri.
              Sabato 27 nella mattinata verrà rilasciato per l'occasione un annullo filatelico speciale per l'occasione.
              La mostra rimarrà aperta e visitabile fino a Domenica 4 giugno.
            </li>
            <li>
              Sabato 27 Maggio in Patronato SS. Redentore raduno delle Guide di Ieri e dei Vecchi Scout. Ore 15:00 mostra delle sedi scout, ore 21:00 fuoco di bivacco con il Gruppo, Vecchi Scout, Guide di Ieri e genitori.
            </li>
            <li>
              Domenica 28 Maggio dalle ore 9:00 fino alle 16:00 in Patronato SS. Redentore raduno delle Guide di Ieri e dei Vecchi Scout con S. Messa e pranzo. Attività di Gruppo la mattina.
            </li>
            <li>
              Giovedì 01 Giugno presso l'aula magna del Collegio Vescovile, incontro "Baden Powell e le origini dello scoutismo". Serata presentata da Loris Beltrami. Incontro aperto alla cittadinanza.
            </li>
            <li>
              Venerdì 02 Giugno alzabandiera in Piazza Maggiore per la Festa della Repubblica.
            </li>
            <li>
              Domenica 11 Giugno dalle ore 9:00 fino alle 16:00 al parco di Villa Benvenuti uscita di Gruppo per fine attività con pranzo per i genitori e cerchio di gioia finale.
            </li>
          </ul>
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </ProgrammaDetails>
    </ProgrammaWrapper>
  );
};

export default Programma;
